import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { ILoginResponse, IUserData } from '../interfaces/UserData.interface';
import { Router } from '@angular/router';
import { ICompany } from '../models/Company.interface';

const LOGIN_RESPONSE_KEY = 'loginResponse';
const USER_DETAILS_KEY = 'user';
const USER_COMPANY_KEY = 'userCompany';
const AUTH_TOKEN_KEY = 'token';
const ORG_NAME_KEY = 'orgName';
const ORG_CODE_KEY = 'orgCode';
const SUB_PLAN_ID_KEY = 'subscriptionPlanId';
const STRIPE_CUSTOMER_ID_KEY = 'stripeCustomerId';
const IS_EXPIREDWITHFAILED_PAYMENT_KEY = 'isExpiredWithFailedPayment';
const LOGIN_ORG_CODE_KEY = 'saveOrgCodeForLogin';
const USER_ROLE_PERMISSION = 'userRolePermissionData';




@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isAuth = false;

  public user: IUserData = null;
  private authToken: string = null;
  public roles: string[] = [];
  public orgName: string = "";
  public authenticatorKey: string = "";


  constructor(private router: Router) { }

  getToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }
  getUserId() {
    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.id;
  }
  getProfileImage() {
    // console.log(JSON.parse(localStorage.getItem(USER_DETAILS_KEY)));
    return (JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.profileImage != null && JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.profileImage != '' ? "https://agility-dev.storage.googleapis.com/" : '') + JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.profileImage;
  }
  getProfileInitials() {
    var init = '';
    var fName = JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.firstName;
    var lName = JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.lastName;
    if (fName != null && fName.length > 0) {
      init = fName[0];
    }
    if (lName != null && lName.length > 0) {
      init += lName[0];
    }
    return init;
  }
  getUserData() {
    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY)) as IUserData;
  }
  getUserCompanyData() {
    return JSON.parse(localStorage.getItem(USER_COMPANY_KEY)) as ICompany[] ?? [];
  }
  getOrgName() {
    return localStorage.getItem(ORG_NAME_KEY);
  }
  getOrganizationId() {
    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.aspNetOrganizationID;
  }
  getSubscriptionPlabId() {
    return localStorage.getItem(SUB_PLAN_ID_KEY);
  }
  getEmail() {
    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.email;
  }
  getContactID() {
    return JSON.parse(localStorage.getItem(USER_DETAILS_KEY))?.contactID;
  }
  getOrgCode() {
    // console.log("localStorage.getItem(ORG_CODE_KEY)", localStorage.getItem(ORG_CODE_KEY));
    return localStorage.getItem(ORG_CODE_KEY);
  }
  getUserProfileData() {
    var data = JSON.parse(localStorage.getItem(USER_DETAILS_KEY));
    return data;
  }
  getStripeCustomerId() {
    return localStorage.getItem(STRIPE_CUSTOMER_ID_KEY);
  }
  getisExpiredWithFailedPayment(): boolean {
    return localStorage.getItem(IS_EXPIREDWITHFAILED_PAYMENT_KEY) == "true";
    // return localStorage.getItem(IS_EXPIREDWITHFAILED_PAYMENT_KEY) == "false";

  }
  getTicketViewPermission(): boolean {
    const s = localStorage.getItem(USER_ROLE_PERMISSION);
    if (s) {
      const data = JSON.parse(s);
      return data['kConstContactTicketView'];
    }
    return false;
  }

  authenticateUser(data: ILoginResponse, autoAuth = false) {
    console.log(data);
    // if (data.userProfile != null && data.userProfile.authenticatorKey != null) {
    //   data.userProfile.authenticatorKey = atob(data.userProfile.authenticatorKey);
    // }
    this.user = data.userProfile;
    this.authToken = data.authToken;
    this.orgName = data.orgName;
    // if (data.userDefinition != null) {
    //   this.authenticatorKey = atob(data.userDefinition);
    // }
    this.roles = data.roles;
    this.isAuthenticated.next(true);
    this.isAuth = true;
    this.saveAuthDataInLocalstorage(data);
    if (!autoAuth) {
      this.router.navigate(['/home']);
    }
  }

  autoAuthneticateUser() {
    if (this.isAuthenticated.value) {
      return;
    }
    const s = localStorage.getItem(LOGIN_RESPONSE_KEY);
    if (s) {
      const data = JSON.parse(s);
      console.log(data);
      this.authenticateUser(data, true);
    }
  }

  updateUserData(data: IUserData) {
    var userProf = JSON.parse(localStorage.getItem(USER_DETAILS_KEY));
    var loginResponseKey = JSON.parse(localStorage.getItem(LOGIN_RESPONSE_KEY));
    userProf.authenticatorMFAOn = data.authenticatorMFAOn;
    userProf.otpMFAOn = data.otpMFAOn;
    userProf.phoneNumber = data.phoneNumber;
    userProf.phoneNumberDialCode = data.phoneNumberDialCode;
    userProf.phoneNumberCountryCode = data.phoneNumberCountryCode;
    userProf.authenticatorKey = data.authenticatorKey;
    userProf.firstName = data.firstName;
    userProf.lastName = data.lastName;

    loginResponseKey.userProfile.authenticatorMFAOn = data.authenticatorMFAOn;
    loginResponseKey.userProfile.firstName = data.firstName;
    loginResponseKey.userProfile.lastName = data.lastName;
    loginResponseKey.userProfile.otpMFAOn = data.otpMFAOn;
    loginResponseKey.userProfile.phoneNumber = data.phoneNumber;
    loginResponseKey.userProfile.phoneNumberDialCode = data.phoneNumberDialCode;
    loginResponseKey.userProfile.phoneNumberCountryCode = data.phoneNumberCountryCode;
    loginResponseKey.userProfile.authenticatorKey = data.authenticatorKey;

    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(userProf));
    this.saveAuthDataInLocalstorage(loginResponseKey);
  }
  setProfileImageOnUpdate(profileImageUrl) {
    var userProf = JSON.parse(localStorage.getItem(USER_DETAILS_KEY));
    var loginResponseKey = JSON.parse(localStorage.getItem(LOGIN_RESPONSE_KEY));
    userProf.profileImage = profileImageUrl;
    loginResponseKey.userProfile.profileImage = profileImageUrl;
    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(userProf));
    this.saveAuthDataInLocalstorage(loginResponseKey);
  }
  setExpiredWithFailPaymentOnUpdate(isExpiredWithFailedPayment: boolean) {
    var loginResponseKey = JSON.parse(localStorage.getItem(LOGIN_RESPONSE_KEY));
    loginResponseKey.isExpiredWithFailedPayment = isExpiredWithFailedPayment;
    this.saveAuthDataInLocalstorage(loginResponseKey);
  }
  saveAuthDataInLocalstorage(data: ILoginResponse) {
    localStorage.setItem(LOGIN_RESPONSE_KEY, JSON.stringify(data));
    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(data.userProfile));
    localStorage.setItem(USER_COMPANY_KEY, JSON.stringify(data.companies));
    localStorage.setItem(AUTH_TOKEN_KEY, data.authToken);
    localStorage.setItem(ORG_NAME_KEY, data.orgName);
    localStorage.setItem(SUB_PLAN_ID_KEY, data.subscriptionPlanId);
    localStorage.setItem(ORG_CODE_KEY, data.orgCode);
    localStorage.setItem(STRIPE_CUSTOMER_ID_KEY, data.stripeCustomerId);
    localStorage.setItem(IS_EXPIREDWITHFAILED_PAYMENT_KEY, `${data.isExpiredWithFailedPayment}`)

  }

  logout() {
    this.isAuth = false;
    this.isAuthenticated.next(false);
    this.clearAuthDataFromLocalstorage();

    this.router.navigate(['/signin']);
  }

  clearAuthDataFromLocalstorage() {
    const s = localStorage.getItem(LOGIN_ORG_CODE_KEY);
    var v = localStorage.getItem("LOGIN_YPR_CONTACT");
    console.log("clearAuthDataFromLocalstorage", s);
    localStorage.clear();
    localStorage.setItem(LOGIN_ORG_CODE_KEY, s);
    localStorage.setItem("LOGIN_YPR_CONTACT", v);

  }
}
